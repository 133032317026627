// necessary set
import React from 'react'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

// import { useLocation, useNavigate } from 'react-router-dom'

// need content

// need tool or method

// Props type
import { IPageProps } from '@common/PropsType'

// Faram type

// need style
import './custody.scss'

interface Props extends IPageProps {
    goTop: () => void
}

/**
 * @author cardo
 * @description
 * sto-user-front: MainContainer
 * Main Container
 */
const CustodyContainer: React.FC<Props> = observer((props) => {
    return (
        <React.Fragment>
            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="custody-content door">
                    {/* <p className="sub-logo"></p> */}
                    <p className="tit">CARDO Vault</p>
                    <p className="sub-tit">가장 안전하고 편리한 법인 전용 커스터디 솔루션</p>

                    <p className="txt">
                        카르도는 발행재단, 블록체인 개발사, NFT 발행사 등 다양한 고객사에게 안전한 디지털자산 커스터디
                        솔루션을 제공합니다.
                    </p>
                    <p className="txt">2023년 말 기준 AUC 2조원 이상의 디지털자산을 보관하고 있습니다.</p>

                    <div className="btn-box">
                        <a
                            href="https://docs.google.com/forms/d/1CAIf3pdz67XrOGilqmteBl-bm5shxtUZkkp754gHGwk/edit"
                            target="_blank"
                            rel="noreferrer"
                        >
                            문의하기
                        </a>
                        <a
                            href="https://docs.google.com/forms/d/1CAIf3pdz67XrOGilqmteBl-bm5shxtUZkkp754gHGwk/edit"
                            target="_blank"
                            rel="noreferrer"
                        >
                            데모신청
                        </a>
                    </div>
                </div>
            </div>

            <div className="section-full custody-subbg">
                <div
                    className="section-part"
                    style={{ '--sectionWidth': '1280px', justifyContent: 'center' } as React.CSSProperties}
                >
                    <p>
                        카르도 볼트는 다수의 메인 네트워크, 토큰 및 NFT <span>자산 수탁을 지원</span>합니다.
                    </p>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="custody-content center">
                    <p className="sub-logo"></p>
                    <p className="tit">지원 가능한 메인 네트워크</p>
                    <div className="coin-box">
                        <span className="btc">BTC</span>
                        <span className="eth">ETH</span>
                        <span className="klay">KLAY</span>
                        <span className="matic">MATIC</span>
                        <span className="sol">SOL</span>
                        <span className="ont">ONT</span>
                        <span className="bnb">BNB</span>
                    </div>

                    <p className="sub-logo"></p>
                    <p className="tit">지원 가능한 NFT 메인 네트워크</p>
                    <div className="coin-box coin-box2">
                        <span className="eth">ETH</span>
                        <span className="klay">KLAY</span>
                        <span className="matic">MATIC</span>
                    </div>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="custody-content pt80">
                    <p className="sub-logo"></p>
                    <p className="tit">
                        카르도 볼트는 강력한 보안과 <br />
                        내부 통제 기능을 갖추고 있습니다.
                    </p>
                    <p className="txt" style={{ margin: '16px 0 0 0' }}>
                        2중 암호화, 콜드월렛, 내화금고 등 강력한 보안 기능을 제공하며,
                    </p>
                    <p className="txt">권한분리 기능을 통해 내부리스크를 제거하고 관련 법규를 준수합니다. </p>
                    <p className="txt">업계에서 가장 많은 네트워크를 지원하며, 권련법을 준수합니다.</p>

                    <div className="security-box">
                        <div className="security-img1"></div>
                        <div className="security-img2"></div>
                        <div className="security-img3"></div>
                    </div>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="custody-content pt100">
                    <div className="tit-img-box">
                        <div className="security"></div>
                        <div style={{ margin: '38px 0 0 0' }}>
                            <p className="sub-logo"></p>
                            <p className="tit">강력한 보안 기술</p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                디지털 지갑의 개인키(Private) 분할 및
                            </p>
                            <p className="txt">이중 암호화 기술을 적용하여 해킹 등 외부 위협으로부터</p>
                            <p className="txt">고객 자산을 최고 수준으로 보호합니다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full b-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="custody-content pt100">
                        <div className="tit-img-box">
                            <div>
                                <p className="sub-logo"></p>
                                <p className="tit">콜드 월렛, 내화 금고</p>
                                <p className="txt" style={{ margin: '16px 0 0 0', color: '#4974B5' }}>
                                    콜드월렛 보관, 내화금고 등 보관 체계를 구축하여
                                </p>
                                <p className="txt" style={{ color: '#4974B5' }}>
                                    최고 수준의 물리적 보안을 제공합니다
                                </p>
                                <p className="txt" style={{ color: '#4974B5' }}>
                                    ISMS 인증을 통해 안정성과 관리 능력을 인정받았습니다.
                                </p>
                            </div>
                            <div className="coldwallnet"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full b-black-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="txt-box">
                        <p className="txt1">
                            카르도 볼트는 <span>검증된 기술력으로</span> <br />
                            <span> 고객의 자산을 안전하고 투명하게 관리</span> 하고 있습니다.
                        </p>
                        <p className="txt2">각종 외부기관의 인증서를 확인해보세요.</p>
                        <p className="btn">
                            <NavLink to="/Company" onClick={() => props.goTop()}>
                                더 알아보기
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div>

            <div className="section-full authority-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="custody-content pt100">
                        <div className="tit-img-box">
                            <div>
                                <p className="tit">권한 분리 기능</p>
                                <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                    권한 부여, 출금 신청, 출금 승인 등의 업무별 권한 분산을 통해
                                </p>
                                <p className="txt">특정 직원이나 부서가 특정 자산에 접근하는 것을 제어합니다.</p>
                                <p className="txt">이는 배임, 횡령 등의 내부통제 리스크 감소에 기여합니다.</p>
                            </div>
                            <div className="authority"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full b-black-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="cardo-txt">
                        <span className="logo-w">법규 준수</span>
                        <p>
                            <span>카르도</span>는 규제 당국의 <span>신고 수리를 완료한 가상자산 사업자</span>로서,
                            <span>제반 규정</span>을 준수하며, 이를 위한 <span>다양한 인적 • 물리적 체계</span>를 갖추고
                            있습니다.
                        </p>
                    </div>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="custody-content center">
                    <p className="txt" style={{ padding: '40px 0 24px 0' }}>
                        카르도 볼트는 안전하고 강력한 보안 체계를 갖추고 있습니다.
                    </p>
                    <p className="tit">CARDO Vault</p>

                    <div className="partners-tit">
                        <span className="tit">주요 고객사</span>
                        {/* <span className="txt">
                            카르도는 <span>00여개 이상의 고객사와 함께</span>하고 있습니다.
                        </span> */}
                    </div>
                    <div className="partners-list">
                        <span className="part-img1"></span>
                        <span className="part-img2"></span>
                        <span className="part-img3"></span>
                        <span className="part-img4"></span>
                        <span className="part-img5"></span>
                        <span className="part-img6"></span>
                        <span className="part-img7"></span>
                        <span className="part-img8"></span>
                        <span className="part-img9"></span>
                        <span className="part-img10"></span>
                        <span className="part-img11"></span>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

export default CustodyContainer
