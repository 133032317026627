// necessary set
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import ReactPaginate from 'react-paginate'
// import { useLocation, useNavigate } from 'react-router-dom'

// need content
import BoardTh from '@pages/board/BoardTh'
import BoardTd from '@pages/board/BoardTd'

import researchList from './research.json'
// need tool or method

// Props type

// Faram type

// need style
import './board.scss'

/**
 * @author cardo
 * @description
 * sto-user-front: researchContents
 * Report Container
 */
const ResearchContents: React.FC = observer((props) => {
    const Items = ({ currentItems }: any) => {
        return (
            <>
                {currentItems &&
                    currentItems.map((data: any, i: any) => (
                        <BoardTd
                            key={i}
                            titleTxt={[
                                { text: String(data.id), width: 10 },
                                { text: data.tit, width: 70 },
                                { text: data.updatedate, width: 20 },
                            ]}
                            pageNumber={data.id}
                            url={data.url}
                        />
                    ))}
            </>
        )
    }

    const [itemOffset, setItemOffset] = useState(0)
    const itemsPerPage = 10
    const endOffset = itemOffset + itemsPerPage
    const currentItems = researchList.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(researchList.length / itemsPerPage)

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % researchList.length
        setItemOffset(newOffset)
    }

    return (
        <React.Fragment>
            <div className="cont">
                <div className="tb-body">
                    <BoardTh
                        titleTxt={[
                            { text: 'no', width: 10 },
                            { text: '제목', width: 70 },
                            { text: '작성일', width: 20 },
                        ]}
                    />

                    <Items currentItems={currentItems} />

                    <ReactPaginate
                        className="paging"
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
        </React.Fragment>
    )
})

export default ResearchContents
