// necessary set
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

// import { useLocation, useNavigate } from 'react-router-dom'

// need content
import BoardTh from '@pages/board/BoardTh'
import BoardTd from '@pages/board/BoardTd'

// import reportList from './report.json'
// import researchList from './research.json'
import trackerList from './tracker.json'

import WeeklyContents from '@pages/board/WeeklyContents'
import ResearchContents from '@pages/board/ResearchContents'

// need tool or method

// Props type

// Faram type

// need style
import './board.scss'

/**
 * @author cardo
 * @description
 * sto-user-front: ReportContainer
 * Report Container
 */
const ReportContainer: React.FC = observer((props) => {
    const [tab, setTab] = useState(1)

    return (
        <React.Fragment>
            <div className="section-full b-black-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="board-door">Report</div>
                </div>
            </div>

            <div className="section-part " style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="report-content">
                    <div className="board-tab">
                        {/* <span>공시자료</span> */}
                        <span className="active">리서치 센터</span>
                        <span>
                            <NavLink to="/Notice">공지사항</NavLink>{' '}
                        </span>
                    </div>
                    <div className="board-subtab">
                        <span className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
                            Weekly Letter
                        </span>
                        <span className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
                            Research
                        </span>
                        <span className={tab === 3 ? 'active' : ''} onClick={() => setTab(3)}>
                            {' '}
                            Tracker Monthly
                        </span>
                    </div>
                    {tab === 1 && <WeeklyContents />}
                    {tab === 2 && <ResearchContents />}
                    {tab === 3 && (
                        <div className="cont">
                            <div className="tb-body">
                                <BoardTh
                                    titleTxt={[
                                        { text: 'no', width: 10 },
                                        { text: '제목', width: 70 },
                                        { text: '작성일', width: 20 },
                                    ]}
                                />

                                {trackerList.map((data, i) => (
                                    <BoardTd
                                        key={i}
                                        titleTxt={[
                                            { text: String(data.id), width: 10 },
                                            { text: data.tit, width: 70 },
                                            { text: data.updatedate, width: 20 },
                                        ]}
                                        pageNumber={data.id}
                                        url={data.url}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
})

export default ReportContainer
