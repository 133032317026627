// necessary set
import React, { useState } from 'react'
import { observer } from 'mobx-react'

// import { useLocation, useNavigate } from 'react-router-dom'

// need content

// need tool or method

// Props type

// Faram type

// need style
import './dca.scss'

/**
 * @author cardo
 * @description
 * sto-user-front: DcaContainer
 * Dca Container
 */
const DcaContainer: React.FC = observer((props) => {
    const [openNum, setOpenNum] = useState(0)
    const qnaOpen = (num: number) => {
        if (openNum === num) {
            setOpenNum(0)
        } else {
            setOpenNum(num)
        }
    }
    return (
        <React.Fragment>
            <div className="section-part row-column" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content">
                    <p className="sub-logo"></p>
                    <p className="tit" style={{ margin: '0 0 16px 0' }}>
                        카르도 적립식 투자는
                        <br />
                        디지털자산 자동 매수 전략을 제공합니다.
                    </p>
                    <p className="txt" style={{ margin: '0 0 32px 0' }}>
                        적립식 투자 전략은 매일, 매주, 매월 규칙적인 간격으로 일정한 금액을 자동으로 투자하는 매수
                        방법입니다. <br />
                        가격에 상관없이 안정적으로 자산을 매수하기 위해 가장 좋은 방법입니다.
                    </p>

                    <div className="infor-img-box">
                        <div className="dca-img1"></div>
                        <div className="dca-img2"></div>
                        <div className="dca-img3"></div>
                    </div>
                </div>
            </div>

            <div className="section-full b-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="section-content">
                        <p className="sub-logo"></p>
                        <p className="tit" style={{ margin: '0 0 16px 0' }}>
                            DCA (Dollar Cost Average)
                        </p>
                        <p className="txt" style={{ margin: '0 0 32px 0', color: '#4974b5' }}>
                            투자에 대한 변동성의 영향을 줄이기 위해 일정한 간격을 두고 고정된 금액을 투자하는 투자 전략
                        </p>

                        <p className="sub-tit2">Benefits of DCA</p>
                        <div className="dca-infor">
                            <div className="infor-box">
                                <p className="tit">꾸준한 포트폴리오 성장</p>
                                <p className="txt">
                                    주기적인 간격으로 일관된 투자는 시간이 지남에 따라 <br />
                                    디지털 자산을 점진적으로 증가시키고 성장에 따른 이익을 보여줍니다.
                                </p>
                            </div>
                            <div className="infor-box">
                                <p className="tit">리스크 감소</p>
                                <p className="txt">
                                    투자를 분산하고 가격 변동을 완화하여 변동성이 큰<br />
                                    디지털 자산 시장의 영향을 관리합니다.
                                </p>
                            </div>
                            <div className="infor-box">
                                <p className="tit">편리성</p>
                                <p className="txt">
                                    매일, 매주 또는 매월 <br />
                                    본인에게 맞는 구매 조건을 선택하세요!
                                </p>
                            </div>
                            <div className="infor-box">
                                <p className="tit">감정적 구매 분리</p>
                                <p className="txt">
                                    자신의 충동적인 결정을 배제하고 디지털자산을 구매할 수 있습니다.
                                    <br />
                                    안정적인 투자를 시작해보세요.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content" style={{ padding: '80px 0' }}>
                    <div>
                        <p className="img-tit">Bitcoin (BTC)</p>
                        <div className="dca-img4"></div>
                    </div>
                </div>
                <div className="section-content" style={{ padding: '80px 0' }}>
                    <div>
                        <p className="img-tit">Crypto prices</p>
                        <div className="dca-img5"></div>
                    </div>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content" style={{ padding: '60px 0' }}>
                    <div className="tit-img-box">
                        <div className="dca-img6"></div>
                        <div style={{ margin: '68px 0 0 0' }}>
                            <p className="sub-logo"></p>
                            <p className="sub-tit">
                                투자 금액, 주기, 기간을 정하면
                                <br />
                                자동으로 투자가 진행됩니다
                            </p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                BTC, ETH, SOL 등 원하는 자산별로 투자 설정 가능
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full b-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="section-content" style={{ padding: '60px 0' }}>
                        <div className="tit-img-box">
                            <div style={{ margin: '68px 0 0 0' }}>
                                <p className="sub-logo"></p>
                                <p className="sub-tit">
                                    국내 원화 입출금 지원
                                    <br />
                                    가상자산 거래소 연결 가능
                                </p>
                                <p className="txt" style={{ margin: '16px 0 0 0', color: '#4974b5' }}>
                                    업비트, 빗썸, 코인원 거래소에 API 연결을 지원
                                </p>
                            </div>
                            <div className="dca-img7"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content" style={{ padding: '60px 0' }}>
                    <div className="dca-infor2">
                        <div className="infor-box">
                            <p className="step">STEP 01</p>
                            <p className="tit">투자 금액 설정</p>
                            <p className="txt">투자 금액은 최소 1만원 이상부터 가능합니다.</p>
                        </div>
                        <div className="infor-box">
                            <p className="step">STEP 02</p>
                            <p className="tit">매수 가장 자산 선택</p>
                            <p className="txt">현재 제공하는 가상자산 종류는 BTC, ETH, SOL 입니다.</p>
                        </div>
                        <div className="infor-box">
                            <p className="step">STEP 03</p>
                            <p className="tit">주기 및 기간 설정</p>
                            <p className="txt">예약한 조건에 따라 자동으로 구매가 진행됩니다.</p>
                        </div>
                        <div className="infor-box">
                            <p className="step">STEP 04</p>
                            <p className="tit">거래소 API 연결</p>
                            <p className="txt">거래소 계정을 연결해야 적립식 투자가 시작됩니다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content" style={{ padding: '60px 0' }}>
                    <p className="sub-logo" style={{ margin: '0 auto 16px' }}></p>
                    <p
                        style={{
                            padding: '0 0 24px 0',
                            textAlign: 'center',
                            fontSize: '32px',
                            fontWeight: '700',
                            lineHeight: '40px',
                            borderBottom: '1px solid #222',
                        }}
                    >
                        자주 하는 질문
                    </p>

                    <div className={openNum === 1 ? 'qna-list open' : 'qna-list'}>
                        <p className="tit" onClick={() => qnaOpen(1)}>
                            <span>Q.</span> DCA는 어떤 서비스 인가요?
                        </p>
                        <div className="txt">
                            DCA는 개인이 저점 매수의 타이밍을 정확히 잡기 어렵기 때문에 일정기간동안 투자금액을 분할하여
                            적립 매수하는 분산투자기법입니다. 카르도 DCA는 이러한 투자를 도와 자동으로 디지털 자산
                            구매를 진행해주는 솔루션입니다.
                        </div>
                    </div>

                    <div className={openNum === 2 ? 'qna-list open' : 'qna-list'}>
                        <p className="tit" onClick={() => qnaOpen(2)}>
                            <span>Q.</span> 거래소 API 연결은 어떻게 하나요?
                        </p>
                        <div className="txt">
                            현재 연결 가능한 거래소는 업비트, 빗썸, 코인원의 거래소 입니다. 개별 거래소마다 API연결
                            방법이 상이하여 사용하고자 하는 거래소를 지정하시어 해당 거래소 연결방법에 따라 설정하시면
                            됩니다.
                        </div>
                    </div>

                    <div className={openNum === 3 ? 'qna-list open' : 'qna-list'}>
                        <p className="tit" onClick={() => qnaOpen(3)}>
                            <span>Q.</span> 적립투자 중에 투자기간을 변경할 수 있나요?
                        </p>
                        <div className="txt">
                            네. 전체 투자 기간이나 투자 간격을 언제든 변경할 수 있습니다. 다만, 먼저 지정된 투자일에는
                            변경이 불가능하며, D-1일 전까지 변경이 가능합니다.
                        </div>
                    </div>

                    <div className={openNum === 4 ? 'qna-list open' : 'qna-list'}>
                        <p className="tit" onClick={() => qnaOpen(4)}>
                            <span>Q.</span> 매수 일시정지가 가능한가요?
                        </p>
                        <div className="txt">
                            네 가능합니다. 일시정지도 가능하며, 원하실 때 다시 투자 재개도 가능합니다.
                        </div>
                    </div>
                    <div className={openNum === 5 ? 'qna-list open' : 'qna-list'}>
                        <p className="tit" onClick={() => qnaOpen(5)}>
                            <span>Q.</span> 투자 금액은 얼마부터 가능한가요?
                        </p>
                        <div className="txt">
                            1회당 1만원부터 가능하며, 원하시는 투자금액을 1만원 단위로 설정 가능합니다.
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full b-black-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="txt-box">
                        <p className="txt1" style={{ margin: '0 0 40px 0', fontSize: '24px' }}>
                            거래소 API 연결 방법
                        </p>
                        <p
                            className="btn"
                            style={{
                                margin: '0 auto 16px',
                                justifyContent: 'center',
                                color: '#fff',
                                backgroundColor: '#0569FF',
                                backgroundImage: 'none',
                                borderRadius: '0 0',
                                cursor: 'auto',
                            }}
                        >
                            API 란?
                        </p>
                        <p className="txt2" style={{ color: '#fff' }}>
                            API는 기업이 제공하는 서비스나 정보를 외부에서 활용할 수 있도록 공개한 연결 통로를
                            의미합니다.
                            <br />
                            카르도는 각 거래소의 API를 이용하여 거래소 계정의 데이터를 수집하고, 보다 편리한 디지털
                            자산을 이용할 수 있는 서비스를 제공합니다.
                        </p>
                    </div>
                </div>
            </div>

            <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content" style={{ padding: '60px 0' }}>
                    <p
                        style={{
                            padding: '0 0 0 0',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: '700',
                            lineHeight: '32px',
                        }}
                    >
                        거래소를 선택하여 연결 방법을 확인하세요!
                    </p>
                    <div className="infor-img-box" style={{ margin: '40px 0 0 0' }}>
                        <a href="https://www.upbit.com/service_center/open_api_guide" target="_blank" rel="noreferrer">
                            <div className="dca-img8"></div>
                        </a>
                        <a href="https://apidocs.bithumb.com/" target="_blank" rel="noreferrer">
                            <div className="dca-img9"></div>
                        </a>
                        <a href="https://coinone.co.kr/user/api/permissions" target="_blank" rel="noreferrer">
                            <div className="dca-img10"></div>
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

export default DcaContainer
