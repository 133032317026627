// necessary set
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

// need content
import ParallaxLayout from '@src/pages/layout/ParallaxLayout'

/**
 *
 * @author cardo
 * @description
 * cardo-home RenderRouters
 * - router 경로 모음
 * - 화면별 layout type 지정
 */
export const RenderRouters = () => {
    // console.log('RenderRouters')

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Navigate to="/"></Navigate>}></Route>
                <Route path="/" element={<ParallaxLayout pageName={'Main'} />} />
                <Route path="/Custody" element={<ParallaxLayout pageName={'Custody'} />} />
                <Route path="/Finance" element={<ParallaxLayout pageName={'Finance'} />} />
                <Route path="/Dca" element={<ParallaxLayout pageName={'Dca'} />} />
                <Route path="/Sto" element={<ParallaxLayout pageName={'Sto'} />} />
                <Route path="/Company" element={<ParallaxLayout pageName={'Company'} />} />
                <Route path="/Notice" element={<ParallaxLayout pageName={'Notice'} />} />
                <Route path="/BoardView/:id" element={<ParallaxLayout pageName={'BoardView'} />} />
                <Route path="/Report" element={<ParallaxLayout pageName={'Report'} />} />
            </Routes>
        </BrowserRouter>
    )
}
