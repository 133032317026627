// necessary set
import React from 'react'
import { observer } from 'mobx-react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// need content

// need tool or method

// Props type

// Faram type

// need style
import './company.scss'
import { getBoardNavigation } from '../board/Board'

/**
 * @author cardo
 * @description
 * sto-user-front: CompanyContainer
 * Company Container
 */
const CompanyContainer: React.FC = observer((props) => {
  return (
    <React.Fragment>
      <div className="section-full company-door">
        <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
          <div className="section-content door">
            <p className="tit2">THE VALUE OF DIGITAL ASSETS</p>
            <p className="txt2">카르도를 만나는 것에서부터 시작됩니다.</p>
            <div className="btn-box btn-box2">
              <a
                href="https://docs.google.com/forms/d/1CAIf3pdz67XrOGilqmteBl-bm5shxtUZkkp754gHGwk/edit"
                target="_blank"
                rel="noreferrer"
              >
                문의하기
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full b-black-border-bg">
        <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
          <div className="cardo-noti">
            <span className="noti-tit">공지사항</span>
            {getBoardNavigation()}
            {/* <ul className="noti">
                <li>
                  <NavLink to="/BoardView/1">- [ 가상자산 오입금 및 출금 유의사항 안내 ]</NavLink>
                </li>
                <li>
                  <NavLink to="/BoardView/2">- [ 고객확인제도(KYC) 안내 ]</NavLink>
                </li>
              </ul>
              <ul className="noti">
                <li>
                  <NavLink to="/BoardView/3">- [ 가상자산이전 시 정보제공(트래블룰) 안내 ]</NavLink>
                </li>
                <li>
                  <NavLink to="/BoardView/4">- [ 가상자산 수탁관련 위험 고지 ]</NavLink>
                </li>
              </ul> */}
          </div>
        </div>
      </div>
      <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
        <div className="section-content" style={{ padding: '80px 0' }}>
          <a href="https://www.kofiu.go.kr/kor/main.do" target="_blank" rel="noreferrer">
            <div className="company-img1"></div>
          </a>
        </div>
        <div className="section-content" style={{ padding: '80px 0' }}>
          <a href="https://isms.kisa.or.kr/main/ispims/issue/?certificationMode=list" target="_blank" rel="noreferrer">
            <div className="company-img2"></div>
          </a>
        </div>
      </div>

      <div className="section-part row-column" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
        <div className="section-content" style={{ padding: '60px 0' }}>
          <p className="his-tit">
            <span> 카르도 연혁</span>
          </p>
          <div className="history">
            <div className="history-box">
              <div className="img-box">
                <div className="company-img3"></div>
              </div>
              <div className="his-infor">
                <p className="year">2023</p>
                <ul className="txt">
                  <li>12월 AUC 2조 돌파 </li>
                  <li>2월 조각투자 플랫폼 공급 협약 체결(최초) </li>
                  <li>1월 한국 NFT 콘텐츠협회 업무 협약</li>
                </ul>
              </div>
            </div>
            <div className="history-box">
              <div className="his-infor right">
                <p className="year">2022</p>
                <ul className="txt">
                  <li>6월 최초 NFT 수탁</li>
                  <li>3월 카르도 리서치센터 개설</li>
                  <li>2월 카르도 커스터디 서비스 시작</li>
                  <li>1월 주식회사 카르도 가상자산사업자 신고 수리 완료</li>
                </ul>
              </div>
              <div className="img-box">
                <div className="company-img4"></div>
              </div>
            </div>
            <div className="history-box">
              <div className="img-box">
                <div className="company-img5"></div>
              </div>
              <div className="his-infor">
                <p className="year">2021</p>
                <ul className="txt">
                  <li>9월 NH농협은행 등 합작 법인 출자</li>
                  <li>9월 주식회사 카르도 ISMS 인증</li>
                  <li>8월 주식회사 카르도 사명 변경</li>
                </ul>
              </div>
            </div>
            <span className="end"></span>
          </div>
        </div>
      </div>

      <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
        <div className="section-content" style={{ padding: '0 0 100px' }}>
          <div className="company-img8"></div>
        </div>
      </div>

      <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
        <div className="section-content">
          <div className="subtit-box">
            <p className="tit tit2">
              CARDO <span>PARTNERS</span>
            </p>
          </div>
          <div className="logo-list company-logo">
            <span className="logo1"></span>
            <span className="logo2"></span>
            <span className="logo3"></span>
            <span className="logo4"></span>
          </div>
          <div className="logo-list company-logo">
            <span className="logo5"></span>
            <span className="logo6"></span>
            <span className="logo7"></span>
          </div>
        </div>
      </div>

      <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
        <div className="section-content">
          <div className="subtit-box">
            <p className="tit tit2">
              CARDO <span>TEAM</span>
            </p>
          </div>
          <div className="company-img6"></div>
        </div>
      </div>

      <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
        <div className="section-content">
          <div className="subtit-box">
            <p className="tit tit2">
              CARDO <span>오시는 길</span>
            </p>
          </div>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.3536642749596!2d127.03308197654452!3d37.499576027963755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3b2b853e477%3A0x857c2c5e4634bdf6!2z7Lm066W064-E!5e0!3m2!1sko!2skr!4v1707897918073!5m2!1sko!2skr"
            className="map"
            title="주소"
          ></iframe>
        </div>
      </div>
    </React.Fragment>
  )
})

export default CompanyContainer
