// necessary set
import React from 'react'
import { observer } from 'mobx-react'

// need style
import '../main.scss'
import { getBoardNavigation } from '../../board/Board'

import PopupContainer from '@src/pages/popup/PopupContainer'

/**
 * @author cardo
 * @description
 * sto-user-front: MainContainer
 * Main Container
 */
const MainContainer: React.FC = observer((props) => {
  return (
    <div className="main-content">
      <PopupContainer pageName={'popup'} />

      <div className="section-full main-door" style={{ backgroundColor: '#000' }}>
        <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
          <div className="door-box">
            <p className="tit">THE VALUE OF DIGITAL ASSETS</p>
            <p className="txt">카르도를 만나는 것에서부터 시작됩니다.</p>
            <p className="btn">
              <a
                href="https://docs.google.com/forms/d/1CAIf3pdz67XrOGilqmteBl-bm5shxtUZkkp754gHGwk/edit"
                target="_blank"
                rel="noreferrer"
              >
                카르도 문의하기
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="section-full b-black-bg">
        <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
          <div className="cardo-noti">
            <span className="noti-tit">공지사항</span>
            {getBoardNavigation()}
          </div>
        </div>
      </div>

      <div className="section-full">
        <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
          <div className="main-infor">
            <div className="tit-img-box">
              <p className="tit">Bitcoin (BTC)</p>
              <div className="bit-img"></div>
            </div>
            <div className="tit-img-box">
              <p className="tit">Crypto prices</p>
              <div className="crypto-img"></div>
            </div>
          </div>
        </div>
        <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
          <div className="main-infor">
            <div className="tit-img-box">
              <div className="hot-img"></div>
            </div>
            <div className="custody-infor">
              <p className="sub-logo"></p>
              <p className="tit">커스터디</p>
              <p className="txt">안전하고 신뢰할 수 있는 보관 시스템</p>
              <p className="btn">
                <a href="/Custody">서비스 바로가기</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full b-bg">
        <div className="section-part" style={{ '--sectionWidth': '1280px', height: '600px' } as React.CSSProperties}>
          <div className="main-infor infor2">
            <div className="custody-infor infor3">
              <p className="sub-logo"></p>
              <p className="tit">적립식 투자</p>
              <p className="txt">가장 강력하고 간단한 투자 전략</p>
              <p className="btn">
                <a href="/Dca">서비스 바로가기</a>
              </p>
            </div>
            <div className="tit-img-box">
              <div className="m-img"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full">
        <div className="section-part" style={{ '--sectionWidth': '1280px', height: '600px' } as React.CSSProperties}>
          <div className="main-infor infor2">
            <div className="tit-img-box">
              <div className="m2-img"></div>
            </div>
            <div className="custody-infor infor4">
              <p className="sub-logo"></p>
              <p className="tit">STO 플랫폼</p>
              <p className="txt">
                실물 자산만 있으면 STO 사업이
                <br />
                가능한 올인원 플랫폼
              </p>
              <p className="btn">
                <a href="/Sto">서비스 바로가기</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full">
        <div className="section-part" style={{ '--sectionWidth': '1280px', height: '600px' } as React.CSSProperties}>
          <div className="main-infor">
            <div className="custody-infor infor6">
              <p className="sub-logo"></p>
              <p className="tit">
                국내 주요 <br />
                보안 인증 획득
              </p>
              <p className="txt">
                국내에서 획득한 보안 시스템으로
                <br />
                자산을 안전하게 보호합니다.
              </p>
            </div>
            <div className="tit-img-box">
              <div className="isms-img"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default MainContainer
