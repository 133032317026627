// necessary set
import React from 'react'
import { observer } from 'mobx-react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Pagination, Navigation } from 'swiper/modules'

// need content

// need tool or method

// Props type

// Faram type

// need style
import './sto.scss'

/**
 * @author cardo
 * @description
 * sto-user-front: StoContainer
 * Sto Container
 */
const StoContainer: React.FC = observer((props) => {
    return (
        <React.Fragment>
            <div className="section-full sto-door">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="section-content door ">
                        <p className="tit">쉽고 간편한 카르도 STO 플랫폼</p>
                        <p className="txt">
                            실물 자산만 있으면 누구나 사업이 가능해 집니다.
                            <br />
                            카르도의 플랫폼 제공 및 관리를 통해 STO 시장에 보다 쉽고, 안정적으로 정착할 수 있습니다.
                        </p>
                        <div className="btn-box">
                            <a
                                href="https://docs.google.com/forms/d/1CAIf3pdz67XrOGilqmteBl-bm5shxtUZkkp754gHGwk/edit"
                                target="_blank"
                                rel="noreferrer"
                            >
                                문의하기
                            </a>
                            <a
                                href="https://docs.google.com/forms/d/1CAIf3pdz67XrOGilqmteBl-bm5shxtUZkkp754gHGwk/edit"
                                target="_blank"
                                rel="noreferrer"
                            >
                                데모신청
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full b-black-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="cardo-txt">
                        <span className="logo-w"></span>
                        <p>
                            해당 이미지는 <span>미술품 기초 자산에 기반</span>하였으며, 미술품 외 다양한 기초 자산으로도
                            <span>STO 플랫폼 사업</span>이 가능합니다.
                        </p>
                    </div>
                </div>
            </div>

            <div className="section-part row-column" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content sto-slider">
                    <div className="slider-tit">
                        <p className="sub-logo"></p>
                        <p>청약 및 발행</p>
                        <p className="slider-txt">
                            실물 자산을 기반으로 자산을
                            <br />
                            청약하고 발행하는 기능을 제공합니다.
                        </p>
                    </div>

                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            1000: {
                                slidesPerView: 1,
                            },
                            1280: {
                                slidesPerView: 3,
                            },
                        }}
                        slidesPerView={3}
                        spaceBetween={0}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="sto-mobile"
                    >
                        <SwiperSlide>
                            <img src="./sto_m_img1.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="./sto_m_img2.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="./sto_m_img3.png" alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className="section-content sto-slider m-sto-slider">
                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            1000: {
                                slidesPerView: 1,
                            },
                            1280: {
                                slidesPerView: 3,
                            },
                        }}
                        slidesPerView={3}
                        spaceBetween={0}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="sto-mobile2"
                    >
                        <SwiperSlide>
                            <img src="./sto_m_img4.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="./sto_m_img5.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="./sto_m_img6.png" alt="" />
                        </SwiperSlide>
                    </Swiper>

                    <div className="slider-tit slider-tit3">
                        <p className="sub-logo"></p>
                        <p>투표 및 매각</p>
                        <p className="slider-txt">
                            자산 매각을 위한 소유자들의
                            <br />
                            투표 기능부터 정산 처리가 가능합니다.
                        </p>
                    </div>
                </div>

                <div className="section-content sto-slider">
                    <div className="slider-tit slider-tit2">
                        <p className="sub-logo"></p>
                        <p>유통 및 거래 내역</p>
                        <p className="slider-txt">
                            자산을 소유한 소유자들간의 매매가 가능하며,
                            <br />
                            거래 내역을 통해 거래 현황을 확인할 수 있습니다.
                        </p>
                    </div>

                    <Swiper
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            1000: {
                                slidesPerView: 1,
                            },
                            1280: {
                                slidesPerView: 3,
                            },
                        }}
                        slidesPerView={3}
                        spaceBetween={0}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="sto-mobile2"
                    >
                        <SwiperSlide>
                            <img src="./sto_m_img7.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="./sto_m_img8.png" alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src="./sto_m_img9.png" alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className="section-content">
                    <div className="tit-img-box">
                        <div className="sto-img1"></div>
                        <div style={{ margin: '48px 0 0 0' }}>
                            <p className="sub-logo"></p>
                            <p className="tit">어드민 관리자</p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                카르도에서 제공하는 관리자 전용 페이지에서 <br />
                                STO 플랫폼을 쉽고 편하게 운영할 수 있습니다.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="section-content">
                    <div className="sub-tit3">STO 사업에 필요한 모든것을 통합 패키지로 제공합니다</div>
                    <div className="sto-img2"></div>
                </div>

                <div className="section-content">
                    <div className="sub-tit3">
                        조각투자 발행, 유통, 관리 그리고 금융 기관 계좌 연결까지 가능한 올인원 플랫폼
                    </div>
                    <div className="sto-img3"></div>
                </div>
            </div>

            <div className="section-full b-sto-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="txt-box">
                        <p className="txt1" style={{ margin: '0 0 40px 0', fontSize: '24px' }}>
                            STO (Security Token Offering) 토큰 증권 생태계도 카르도가 앞장서겠습니다.
                        </p>
                        <p
                            className="btn"
                            style={{
                                width: '320px',
                                margin: '0 auto 16px',
                                justifyContent: 'center',
                                color: '#fff',
                                backgroundColor: '#0569FF',
                                backgroundImage: 'none',
                                borderRadius: '0 0',
                                cursor: 'auto',
                            }}
                        >
                            카르도는 주주사 NH농협은행과 함께합니다.
                        </p>
                        <p></p>
                    </div>
                </div>
            </div>

            <div className="section-part row-column" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content">
                    <p className="sub-logo" style={{ margin: '0 auto 16px' }}></p>
                    <div className="sub-tit4">카르도는 STO 및 토큰 증권 사업 모델의 협업을 기대합니다.</div>
                    <p className="txt2">STO 플랫폼 서비스를 활용할 수 있는 좋은 의견을 기다리고 있습니다.</p>
                    <p className="btn">
                        <a
                            href="https://docs.google.com/forms/d/1CAIf3pdz67XrOGilqmteBl-bm5shxtUZkkp754gHGwk/edit"
                            target="_blank"
                            rel="noreferrer"
                        >
                            문의하기
                        </a>
                    </p>
                    <div className="sto-img4" style={{ margin: '0 auto' }}></div>
                </div>

                <div className="section-content">
                    <div className="sto-img5" style={{ margin: '0 auto 40px' }}></div>
                    <div className="sto-img6"></div>
                </div>

                <div className="section-content">
                    <div className="subtit-box">
                        <p className="tit">
                            CARDO <span>PARTNERS</span>
                        </p>
                        {/* <p className="txt">
                            카르도는 <span>00여개 이상의 고객사와 함께</span>하고 있습니다.
                        </p> */}
                    </div>
                    <div className="logo-list sto-logo">
                        <span className="logo1"></span>
                        <span className="logo2"></span>
                        <span className="logo3"></span>
                        <span className="logo4"></span>
                        <span className="logo5"></span>
                    </div>
                </div>

                <div className="section-content">
                    <p className="tit">STO 공급 사례</p>
                    <div className="tit-img-box" style={{ margin: '0 0 60px 0' }}>
                        <div className="tit-txtbox2">
                            <p className="sub-tit">갤럭시아머니트리 주식회사</p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                갤럭시아머니트리는 종합생활금융 플랫폼 서비스 기업으로서,
                                <br />
                                앞선 기술력과 차별화된 서비스 경쟁력으로 전자 결제, O2O 비즈니스 및 머니트리
                                <br />
                                (Money Tree) 서비스를 통해 온ᆞ오프라인 결제 시장을 선도하고 있습니다.
                            </p>
                        </div>
                        <div className="sto-img7"></div>
                    </div>

                    <div className="tit-img-box">
                        <div className="sto-img8"></div>
                        <div style={{ margin: '28px 0 0 0' }}>
                            <p className="sub-tit">주식회사 아티피오</p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                아티피오는 예스24의 자회사로, 미술품 거래 사업을 담당하는 플랫폼 회사입니다.
                                <br />
                                국내외 인기 작가의 미술품 소유권을 여러 공유 지분으로 분할해 판매하는 서비스로, <br />
                                일반 대중도 부담없이 예술 작품을 구매할 수 있어 <br />
                                미술품 소유 대중화에 앞장서고 있습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

export default StoContainer
