import React, { useState } from 'react'

// necessary set
// import { NavLink } from 'react-router-dom'

// import React from 'react'
import { observer } from 'mobx-react'

// need content

// Props type
import { IPageProps } from '@common/PropsType'

// need style
import '@pages/popup/popup.scss'
import popupDataList from './popup.json'
import { PopupData } from './Popup.type'

interface Props extends IPageProps {
  // goTop: () => void
  // setClose: (data: boolean) => void
}

/**
 * @author cardo
 * @description
 * sto-user-front: footer
 * 기본 footer
 */
const PopupContainer: React.FC<Props> = observer(() => {
  if (popupDataList == null || popupDataList.length < 1) {
    return <></>
  }
  const popupList = popupDataList as PopupData[]
  const sortedPopups: PopupData[] = popupList.sort((a, b) => a.order - b.order) as PopupData[]
  // 열지 않는 처리
  // const neverOpen = localStorage.getItem('neverOpen')
  // if (neverOpen != null) {
  //   const neverOpenList = neverOpen.split(',')
  //   sortedPopups.forEach((popup, index) => {
  //     if (neverOpenList.includes(popup.id.toString())) {
  //       sortedPopups.splice(index, 1)
  //     }
  //   })
  // }
  const [popups, setPopups] = useState(sortedPopups)
  const close = (index: number, popupData: PopupData, neverOpen?: number) => {
    popups.splice(index, 1)
    // if (popups.length < 1) {
    //   props.setClose(true)
    // }
    setPopups([...popups])
    // if (neverOpen != null) {
    // }
  }

  return popups && popups.length > 0 ? (
    <div className="popup-bg">
      <div className="popup-container">
        <div className="popup-area">
          {popups.map((popupData, index) => (
            <div className="popup">
              <div className="tit">{popupData.title}</div>
              <div className="txt" dangerouslySetInnerHTML={{ __html: popupData.content }}></div>
              {popupData.footer != null && popupData.footer.length > 0 ? (
                <div className="footer">
                  <div dangerouslySetInnerHTML={{ __html: popupData.footer }}></div>
                </div>
              ) : undefined}
              <p className="close" onClick={() => close(index, popupData)}>
                x
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
})

export default PopupContainer
