// necessary set
// import React, { useState, useRef } from 'react'
import React, { useRef } from 'react'
import { observer } from 'mobx-react'

// need content
// import Loading from '../loading/Loading'

import Top from '@pages/layout/Top'
import Footer from '@pages/layout/Footer'
import MainContainer from '@src/pages/main/container/MainContainer'
import CustodyContainer from '@src/pages/custody/CustodyContainer'
import FinanceContainer from '@src/pages/finance/FinanceContainer'
import DcaContainer from '@src/pages/dca/DcaContainer'
import StoContainer from '@src/pages/sto/StoContainer'
import CompanyContainer from '@src/pages/company/CompanyContainer'
import NoticeContainer from '@src/pages/board/NoticeContainer'
import ReportContainer from '@src/pages/board/ReportContainer'
import BoardView from '@src/pages/board/BoardView'

// Props type
import { IPageProps } from '@common/PropsType'

// need style
import '@pages/layout/layout.scss'

/**
 * @author cardo
 * @description
 * sto-user-front: ParallaxLayout
 * Paralla xLayout
 */
const ParallaxLayout: React.FC<IPageProps> = observer((props) => {
  // layout thema

  const parallaxLayout = useRef<HTMLDivElement | null>(null)

  const goTop = () => {
    parallaxLayout?.current?.lastElementChild?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="parallax-layout" ref={parallaxLayout}>
      <div className="go-up" onClick={goTop}></div>
      <div className="container">
        <Top pageName={props.pageName} subName={props.subName} goTop={goTop} />

        {props.pageName === 'Main' && (
          <div className="content">
            <MainContainer />
          </div>
        )}
        {props.pageName === 'Custody' && <CustodyContainer pageName={props.pageName} goTop={goTop} />}
        {props.pageName === 'Finance' && <FinanceContainer />}
        {props.pageName === 'Dca' && <DcaContainer />}
        {props.pageName === 'Sto' && <StoContainer />}

        {props.pageName === 'Company' && <CompanyContainer />}
        {props.pageName === 'Notice' && <NoticeContainer />}
        {props.pageName === 'BoardView' && <BoardView />}
        {props.pageName === 'Report' && <ReportContainer />}

        <Footer pageName={props.pageName} goTop={goTop} />
      </div>
    </div>
  )
})

export default ParallaxLayout
