// necessary set
import React from 'react'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

// Props type
import { IPageProps } from '@common/PropsType'

// need style
import '@pages/layout/top.scss'

interface Props extends IPageProps {
    goTop: () => void
}

/**
 *
 * @author cardo
 *
 * @description
 * cardo-home : top
 * 기본 top
 */
const Top: React.FC<Props> = observer((props) => {
    const subLink = (url: string) => {
        window.location.href = url
    }

    return (
        <header>
            <div className="top-content">
                <h1 className="logo">
                    <NavLink to="/" onClick={() => props.goTop()}></NavLink>
                </h1>
                <div className="top-menu">
                    <div>
                        <NavLink
                            to="/Custody"
                            className={({ isActive }) => [isActive ? 'active' : ''].join(' ')}
                            onClick={() => props.goTop()}
                        >
                            커스터디
                        </NavLink>
                    </div>
                    <div>
                        <NavLink
                            to="/Finance"
                            className={({ isActive }) => [isActive ? 'active' : ''].join(' ')}
                            onClick={() => props.goTop()}
                        >
                            금융 서비스
                        </NavLink>
                    </div>

                    <div>
                        <NavLink
                            to="/Sto"
                            className={({ isActive }) => [isActive ? 'active' : ''].join(' ')}
                            onClick={() => props.goTop()}
                        >
                            STO
                        </NavLink>
                    </div>
                    <div>
                        <NavLink
                            to="/Dca"
                            className={({ isActive }) => [isActive ? 'active' : ''].join(' ')}
                            onClick={() => props.goTop()}
                        >
                            DCA
                        </NavLink>
                    </div>
                    <div>
                        <NavLink
                            to="/Company"
                            className={({ isActive }) => [isActive ? 'active' : ''].join(' ')}
                            onClick={() => props.goTop()}
                        >
                            회사소개
                            <ul className="dropdown_menu dropdown_menu-2">
                                <li className="dropdown_item-1">
                                    <span onClick={() => subLink('/Notice')}>공지사항</span>
                                </li>
                            </ul>
                        </NavLink>
                    </div>
                </div>

                <div className="hamburger-menu">
                    <input id="menu__toggle" type="checkbox" />
                    <label className="menu__btn" htmlFor="menu__toggle">
                        <span></span>
                    </label>

                    <ul className="menu__box">
                        <li>
                            <a className="menu__item" href="/">
                                HOME
                            </a>
                        </li>
                        <li>
                            <a className="menu__item" href="/Custody">
                                커스터디
                            </a>
                        </li>
                        <li>
                            <a className="menu__item" href="/Finance">
                                금융 서비스
                            </a>
                        </li>
                        <li>
                            <a className="menu__item" href="/Sto">
                                STO
                            </a>
                        </li>
                        <li>
                            <a className="menu__item" href="/Dca">
                                DCA
                            </a>
                        </li>

                        <li>
                            <a className="menu__item" href="/Company">
                                회사소개
                            </a>
                        </li>
                        <li>
                            <a className="menu__item" href="/Notice">
                                공지사항
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
})

export default Top
