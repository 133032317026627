// necessary set
import React from 'react'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'

// import { useLocation, useNavigate } from 'react-router-dom'

// need content

// need tool or method

// Props type

// Faram type

// need style
import './finance.scss'

/**
 * @author cardo
 * @description
 * sto-user-front: FinanceContainer
 * Finance Container
 */
const FinanceContainer: React.FC = observer((props) => {
    return (
        <React.Fragment>
            <div className="section-part row-column" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content">
                    <p className="sub-logo"></p>
                    <p className="tit" style={{ margin: '0 0 16px 0' }}>
                        가장 신뢰할 수 있는
                        <br />
                        금융회사 전용 커스터디 솔루션
                    </p>
                    <p className="txt" style={{ margin: '0 0 32px 0' }}>
                        은행, 증권사, 운용사 등 금융기관 전용 서비스로 금융 회사 고유의 디지털자산 보관 및 이전이
                        가능하며
                        <br />
                        세무/회계 등 금융 회사에 필요한 디지털자산 관련 기능을 제공합니다.
                    </p>
                    <p className="txt" style={{ margin: '0 0 100px 0' }}>
                        SOC II 인증, ISMS 등 인증을 취득하여 안전한 관리 기능을 제공합니다.
                    </p>
                    <div className="infor-img-box">
                        <div className="finance-img1"></div>
                        <div className="finance-img2"></div>
                        <div className="finance-img3"></div>
                    </div>
                </div>

                <div className="section-content">
                    <div className="tit-img-box" style={{ margin: '0 0 40px 0' }}>
                        <div className="isms-one"></div>
                        <div className="tit-txtbox">
                            <p className="sub-logo"></p>
                            <p className="tit">
                                금융 회사 고유 자산의
                                <br />
                                안전한 보관
                            </p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                SOC II 인증, ISMS 인증을 받는 솔루션으로, 강력한 보안기능을 제공합니다.
                                <br />
                                2중 암호화, 콜드월렛 보관, 내화금고 등 다층적인 보안 체계를 구축하여
                                <br />
                                고객의 자산을 최고 수준으로 보호합니다.
                            </p>
                        </div>
                    </div>
                    <div className="tit-img-box">
                        <p className="sub-tit" style={{ margin: '60px 100px 16px 0' }}>
                            다양한 디지털 자산을
                            <br />
                            안전하게 보관하고 관리합니다.
                        </p>
                        <div className="img-box" style={{ width: '780px' }}>
                            <div className="finance-img4"></div>
                            <div className="finance-img5"></div>
                        </div>
                    </div>
                </div>

                <div className="section-content" style={{ padding: '40px 0' }}>
                    <div className="tit-img-box">
                        <div className="tit-txtbox2">
                            <p className="sub-logo"></p>
                            <p className="tit">
                                디지털 자산
                                <br />
                                비즈니스의 동반자
                            </p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                디지털자산 관련 비즈니스에서 금융 회사의 부담을 덜어드립니다.
                                <br />
                                블록체인 기술과 경험을 바탕으로 디지털자산의 전문성을 지원합니다.
                            </p>
                        </div>
                        <div className="finance-img6"></div>
                    </div>
                </div>

                <div className="section-content">
                    <div className="tit-img-box">
                        <div className="finance-img7"></div>
                        <div className="tit-txtbox2">
                            <p className="sub-logo"></p>
                            <p className="tit">신속한 입 · 출금</p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                트래블룰 솔루션을 통한 간편한 입출금을 지원합니다.
                                <br />
                                매번 전체 지갑 주소를 입력할
                                <br />
                                필요없이 간편하게 이전할 수 있습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-full b-bg">
                <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                    <div className="section-content" style={{ padding: '40px 0' }}>
                        <div className="tit-img-box">
                            <div className="tit-txtbox2">
                                <p className="sub-logo"></p>
                                <p className="tit">디지털 금융 자산</p>
                                <p className="txt" style={{ margin: '16px 0 0 0', color: '#4974b5' }}>
                                    금융 회사의 디지털자산
                                    <br />
                                    세무/회계를 위한 자료를 제공합니다.
                                </p>
                            </div>
                            <div className="finance-img8"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-part row-column" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>
                <div className="section-content">
                    <div className="subtit-box">
                        <p className="tit">
                            CARDO <span>PARTNERS</span>
                        </p>
                        {/* <p className="txt">
                            카르도는 <span>00여개 이상의 고객사와 함께</span>하고 있습니다.
                        </p> */}
                    </div>
                    <div className="logo-list finance-logo">
                        <span className="logo1"></span>
                        <span className="logo2"></span>
                        <span className="logo3"></span>
                        <span className="logo4"></span>
                        <span className="logo5"></span>
                        <span className="logo6"></span>
                    </div>
                </div>

                <div className="section-content">
                    <div className="tit-img-box">
                        <div className="tit-txtbox2">
                            <p className="sub-logo"></p>
                            <p className="tit">
                                카르도
                                <br />
                                리서치 센터
                            </p>
                            <p className="txt" style={{ margin: '16px 0 0 0' }}>
                                디지털자산에 대한 연구 자료와 최신뉴스를 제공합니다.
                            </p>
                        </div>
                        <div className="finance-img9">
                            <NavLink to="/Report"></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
})

export default FinanceContainer
