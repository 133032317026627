// necessary set
import React from 'react'
import { observer } from 'mobx-react'

// need content

// Props type
import { ITableProps } from '@common/PropsType'

// need style

/**
 *
 * @author sto
 *
 * @description
 * sto-admin-front: BoardTh
 * 기본 BoardTh
 */
const BoardTh: React.FC<ITableProps> = observer((props) => {
    return (
        <div className="tb-tit">
            {props?.titleTxt &&
                props.titleTxt.map((data, i) => (
                    <span key={i} style={{ width: `${data.width}%` }}>
                        {data.text}
                    </span>
                ))}
        </div>
    )
})

export default BoardTh
