import { NavLink } from 'react-router-dom'
import { Board } from './Board.type'
import boardListOrigin from './board.json'

export const boardList = boardListOrigin.sort((a, b) => a.order - b.order)

const boardMap: Record<number, Board> = {}
boardList.forEach((board) => {
  boardMap[board.id] = board
})

export const getBoard = (id: number) => {
  const board = boardMap[id]
  if (board.createTime == null) {
    board.createTime = ''
  } //
  else {
    const d = new Date(board.createTime)
    if (!isNaN(d.getTime())) {
      board.createTime = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d
        .getDate()
        .toString()
        .padStart(2, '0')}`
    }
  }
  return board
}

const partition = []
for (let i = 0; i < boardList.length; i += 6) {
  partition[partition.length] = boardList.slice(i, i + 6)
}
export const getBoardNavigation = (limit = 6) => {
  const sorted = boardList.slice(0, limit)
  const spacing = 2
  const partition = []
  for (let i = 0; i < sorted.length; i += spacing) {
    partition[partition.length] = sorted.slice(i, i + spacing)
  }
  return partition.map((part) => {
    return (
      <ul className="noti">
        {part.map((b) => (
          <li>
            <NavLink to={`/BoardView/${b.id}`}>- [ {b.tit} ]</NavLink>
          </li>
        ))}
      </ul>
    )
  })
}
