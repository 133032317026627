import React from 'react'

// necessary set
import { NavLink } from 'react-router-dom'

// import React from 'react'
import { observer } from 'mobx-react'

// need content

// Props type
import { IPageProps } from '@common/PropsType'

// need style
import '@pages/layout/footer.scss'

interface Props extends IPageProps {
    goTop: () => void
}

/**
 * @author cardo
 * @description
 * sto-user-front: footer
 * 기본 footer
 */
const Footer: React.FC<Props> = observer((props) => {
    return (
        <footer>
            <div className="footer-content">
                <p className="footer-logo"></p>
                <div className="company-infor">
                    <span className="tit">상호명</span>
                    <span className="txt">(주) 카르도</span>
                </div>
                <div className="company-infor">
                    <span className="tit">주소</span>
                    <span className="txt">(06236) 서울특별시 강남구 논현로 87길 15 LS빌딩 10층</span>
                </div>
                <div className="company-infor">
                    <span className="tit">사업자 등록번호</span>
                    <span className="txt">774-87-02041</span>
                </div>
                <div className="company-infor">
                    <span className="tit">대표자</span>
                    <span className="txt">이청우</span>
                </div>
                <div className="link-box">
                    <ul className="footer-link">
                        <li>회사소개</li>
                        <li>
                            <NavLink to="/Company" onClick={() => props.goTop()}>
                                회사소개
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Notice" onClick={() => props.goTop()}>
                                공지사항
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Custody" onClick={() => props.goTop()}>
                                커스터디
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Finance" onClick={() => props.goTop()}>
                                금융 서비스
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Sto" onClick={() => props.goTop()}>
                                STO
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Dca" onClick={() => props.goTop()}>
                                DCA
                            </NavLink>
                        </li>
                    </ul>
                    <ul className="customer-link">
                        <li>고객지원</li>
                        {/* <li>
                            <NavLink to="/About">Contact</NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink to="/">채용문의</NavLink>
                        </li> */}

                        <li className="privacy">
                            <NavLink to="/privacy.html" target="_blank">
                                개인정보 처리 방침
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/service.html" target="_blank">
                                서비스 이용 약관
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="company-txt">
                    <p>디지털 자산 보유는 높은 수준의 위험을 수반하며 큰 변동성을 가지고 있습니다.</p>
                    <p>
                        따라서 귀하의 디지털 자산 보유에 있어 가치가 크게 변동할 수 있으며 심지어 마가치해질 수도
                        있습니다.
                    </p>
                </div>
                <div className="reserved">©2024 Cardo. All rights reserved.</div>
            </div>
        </footer>
    )
})

export default Footer
